import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/main';
import Head from '../components/layout/head';

const NotFound = () => {
  const { notfound } = useStaticQuery(graphql`
    query {
      notfound: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title={'Page Not Found'} />

      <div className="box-container mt-5 pb-5 px-3 px-md-5 text-center">
        <div>
          <BackgroundImage
            fluid={notfound.childImageSharp.fluid}
            className="not-found-page-image mx-auto"
          ></BackgroundImage>
        </div>
        <div className="fs-35 ganen-sky-blue my-4">There’s Nothing Here...</div>
        <div className="fs-22 xs-fs-15">
          We can’t find the page you’re looking for.
        </div>
        <div className="fs-22 mb-5 xs-fs-15">Please check the URL.</div>

        {/*}
        <Link to="/" className="fs-22 go-home">
          Go Home
        </Link>
        */}
      </div>
    </Layout>
  );
};

export default NotFound;
